import { Tabs } from "antd";
import { platformStore } from "../../../store/platformStore";
import { createContext,useContext, useEffect } from "react";
import MemberReport from "../report/MemberReport";
import OrdinationReport from "../report/OrdinationReport";
import adminStore from "../../../store/adminstore";
import MemberProvincilaReport from "../report/MemberProvincilaReport";
import ProvincialOrdinationReport from "../report/ProvincialOrdinationReport";
import GeneralReport from "./GeneralReport";
const platformStoreCtx = createContext(platformStore);
const adminStoreCtx = createContext(adminStore);
const { TabPane } = Tabs;
type Props = {};

const ReportBaseView = (props: Props) => {
  const PlatformStore = useContext(platformStoreCtx);
  const AdminStore = useContext(adminStoreCtx);
   useEffect(() => {
      async function getAllUsers() {
        await PlatformStore.getAllCmc();
        await PlatformStore.getAllProvince();
        await PlatformStore.getAllDistrict();
        await PlatformStore.getAllBranch();
      }
      getAllUsers();
      return () => {};
    }, []);
  return (
    <GeneralReport/>
    // <Tabs defaultActiveKey="1"
    // onChange={async(key) => {
    //   // setTab(key.toString());
    //   if(key === "2" || key === "3" || key === "4" || key === "5"){
    //     AdminStore.ordinationReportData.clear()
    //     AdminStore.memberReportData.clear()
    //     AdminStore.branchOrdinationValueForRefresh = undefined
    //     AdminStore.branchValueForRefresh = undefined

    //     AdminStore.selectedBranch = undefined
    //     AdminStore.selectedCmc = undefined
    //     AdminStore.selectedDistrict = undefined
    //     AdminStore.selectedProvince = undefined
    //     AdminStore.selectedGender = undefined
    //   }
    // }}
    // >
    //   <TabPane tab="Members Report" key="1">
    //     <MemberReport/>
    //   </TabPane>
    //   <TabPane tab="Ordination Report" key="2">
    //     <OrdinationReport/>
    //   </TabPane>
    //   <TabPane tab="Provincial Level Members Report" key="3">
    //     <MemberProvincilaReport/>
    //   </TabPane>
    //   <TabPane tab="Provincial Level Ordination Report" key="4">
    //     <ProvincialOrdinationReport/>
    //   </TabPane>
    // </Tabs>
  );
};

export default ReportBaseView;
