import { client } from "../../../Infrastructure/agent";
import { EDBRequestResponse } from "../../../shared/EDBRequestResponse";
import { IGroup, IMemberUpload, IOrdination, IOrdinationUpload, IUploadInfo, IUserData } from "../../Admin/types/interface";
import { IUser } from "../../Home/types/interfaces";
export const AdminService={

    registerMemberFromPlatform:(userData:IUser,password:string):Promise<EDBRequestResponse>=>client.post('/main/registerUserFromSystem',{userData,password}),

    memberUploadFromExcel:(members:Array<IMemberUpload>,branchId:number,uploadInfo:IUploadInfo):Promise<EDBRequestResponse>=>client.post('/main/RegisterMembersFromUploadData',{members,branchId,uploadInfo}),
    ordinationUploadFromExcel:(ordinations:Array<IOrdinationUpload>):Promise<EDBRequestResponse>=>client.post('/main/OrdinationsFromUploadData',{ordinations}),
    getGeneralReport:(branchId:number):Promise<EDBRequestResponse>=>client.post('/main/generateMemberReport',{branchId}),

    searchFroMember:(searchString:string):Promise<EDBRequestResponse>=>client.post('/main/memberDetailsSearch',{searchString}),
    updateOrdination:(ordination:IOrdination):Promise<EDBRequestResponse>=>client.post('/ordination/modifyOrdination',{ordination}),
    deleOrdination:(ordinationId:number):Promise<EDBRequestResponse>=>client.post('/ordination/deleteOrdination',{ordinationId}),
    getSearchedMemberAndOrdination:(userId:string)=>client.post('/main/loadUserProfileAndOrdination',{userId}),
    updateGroup:(userId:string):Promise<EDBRequestResponse>=>client.post('/administration/changeUserGroup',{userId}),

    getUsersAndGroups:(facilityId:number)=>client.post('/administration/getaccountgroups',{facilityId}),
    getAllUsersGroup:(facilityId:number)=>client.post('/administration/getAllUserGroups',{facilityId}),
    saveAccountGroups:(group:IGroup)=>client.post('/administration/saveaccountgroups',{group,copyFrom:group.copyFromGroupId}),
    removeGroup:(group:IGroup)=>client.post('/administration/removeaccountgroups',{group}),
    searchUser:(searchTerms:string,searchCategory:string)=>client.post('/administration/searchUser',{searchTerms,searchCategory}),
    sendInivite:(email:string)=>client.post('/administration/InviteSatff',{email}),
    registerSatff:(userId:string,facilityId:number,groupIds:Array<number>)=>client.post('/administration/registerSatff',{userId,facilityId,groupIds}),
    removeFacilityUser:(id:string,facilityId:number)=>client.post('/administration/removeFacilityUser',{id,facilityId}),
    lockUserAccout:(userId:string):Promise<EDBRequestResponse>=>client.post('/administration/lockuseraccount',{userId}),
    getAllUsers:()=>client.get('/administration/getallusers'),
    getSingleUsers:(userId:string):Promise<EDBRequestResponse>=>client.post('/administration/getAUsers',{userId}),
    getAllGroupAsync:():Promise<EDBRequestResponse>=>client.get('/administration/getAllGroup'),
    reInitalizePermissions:()=>client.post('/administration/ReInitializePermisions'),
    getGroupPermissions:(groupId:number)=>client.post('/administration/GetGroupPermissions',{groupId}),
    getUsersInGroup:(groupId:number,facilityId:number)=>client.post('/administration/getUsersInGroup',{groupId,facilityId}),
    getUsersInAGroup:(groupId:number):Promise<EDBRequestResponse>=>client.post('/administration/getUsersInAGroup',{groupId}),
    removeUserFromGroup:(userId:string,groupId:number,facilityId:number)=>client.post('/administration/RemoveUserFromGroup',{userId,groupId,facilityId}),
    addUsersToGroup:(users:Array<IUserData>, groupId:number,facilityId:number)=>client.post('/administration/AddUsersToGroup',{users,groupId,facilityId}),

    getUserGroups: (userId:string,facilityId:number) => {
      return client.post("/administration/GetUserGroups", { userId,facilityId });
    },
    getUserPermissions: (userId:string,facilityId:number) => {
      return client.post("/administration/getuserpermissions", { userId,facilityId });
    },
    
    // report
    getOrdinationReportFromDb:(branchId:number,gender:string)=>client.post('/ordination/getAMemberOrdinationReport',{branchId,gender}),
    getMemberReportFromDb:(branchId:number,gender:string)=>client.post('/ordination/getAMemberReport',{branchId,gender}),

    // Provincial Level Report
    getProvincialLevelOrdinationReportFromDb:(provinceId:number,gender:string)=>client.post('/ordination/getAProvinceMemberOrdinationReport',{provinceId,gender}),
    getProvincialLevelMemberReportFromDb:(provinceId:number,gender:string)=>client.post('/ordination/getAProvinceMemberReport',{provinceId,gender}),
    downloadBranchTemplate:(branchId:number)=>client.getFile("/main/RenderBranchReport",{branchId})

}