import Meta from 'antd/lib/card/Meta';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { observer } from 'mobx-react-lite';
import { BookOpen,User,Users } from 'react-feather';
import "../../../MasterPage/styles/grid.css"
import Chart, { Chart2 } from './Chart';
import { createContext, useContext, useEffect } from 'react';
import dashboardStore from '../../store/dashboardStore';
import { Divider, Skeleton } from 'antd';
import { CalculateMemberInProvinceForPie, IMaleAndFemaleCountInProvince, IPieChartReturn } from '../../types/interfaces';

const dashboardStoreCtx = createContext(dashboardStore);
const DashboardBaseView = observer(() => {
    const DashboardStore = useContext(dashboardStoreCtx);
    useEffect(() => {
        async function LoadData() {
            await DashboardStore.getAllDashboardData()
            let d = [...DashboardStore.membersInProvinceForPie.values()].map(x=>x.membersDivTotalMembersMulByThreeSixty);
            DashboardStore.maximumNumberForPieData = Math.max(...d);
        }
        LoadData();
      }, []);
    
    return(<>
        <Divider orientation="left">
        <h3 style={{ padding: "5px", color: "#0e76bc" }}>DASHBOARD</h3>
      </Divider>
        {DashboardStore.isLoading  ? (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <>
            <div className="cards">
                <div className="card">
                    <Meta
                        avatar={<User style={{ color: "#D92525" }} size={50} />}
                        title={<h1 style={{ color: "#1890ff" }}>TOTAL MALE</h1>}
                        description={
                            <span className="feedback-dashboard-value">
                                {DashboardStore.totals.male}
                            </span>
                        }
                    />
                </div>
                <div className="card">
                    <Meta
                        avatar={<User style={{ color: "#D92525" }} size={50} />}
                        title={<h1 style={{ color: "#1890ff" }}>TOTAL FEMALE</h1>}
                        description={
                        <span className="feedback-dashboard-value">
                            {DashboardStore.totals.female}
                        </span>
                        }
                    />
                </div>
                <div className="card">
                <Meta
                    avatar={<Users style={{ color: "#D92525" }} size={60} />}
                    title={<h1 style={{ color: "#1890ff" }}>TOTAL MEMBERS</h1>}
                    description={
                    <span className="feedback-dashboard-value">
                        {DashboardStore.totals.total}
                    </span>
                    }
                />
                </div>
            </div>
            
            <div className="ms-Grid" dir="ltr" style={{ paddingTop: "10%" }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md7 ms-lg7">
                        <Chart
                            title={'MALE AND FEMALE DISTRIBUTION OF TOTAL POPULATION IN EACH PROVINCE'}
                            subtitle={'This chart illustrates the total number of both male and female in each province.'}
                            color={''}
                            maleData={[...DashboardStore.provinceTotalMaleAndFemale.values()].map((value: IMaleAndFemaleCountInProvince)=> value.male)}
                            femaleData={[...DashboardStore.provinceTotalMaleAndFemale.values()].map((value: IMaleAndFemaleCountInProvince)=> value.female)}
                            province={[...DashboardStore.provinceTotalMaleAndFemale.values()].map((value: IMaleAndFemaleCountInProvince)=> value.provinceName)}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md5 ms-lg15">
                    <Chart2 
                            title={'PERCENTAGE OF MEMBERS IN EACH PROVINCE'}
                            subtitle={"This chart illustrates the total percentage distribution of both male and female populations in each province."}
                            data={[...DashboardStore.membersInProvinceForPie.values()].map((value: CalculateMemberInProvinceForPie)=>{
                                let d:IPieChartReturn = {
                                    name:value.provinceName,
                                    y:value.membersDivTotalMembersMulByThreeSixty,
                                    selected:DashboardStore.maximumNumberForPieData === value.membersDivTotalMembersMulByThreeSixty?true:false,
                                    sliced:DashboardStore.maximumNumberForPieData === value.membersDivTotalMembersMulByThreeSixty?true:false,
                                }
                               return d
                              
                            })}
                        />
                    </div>
                </div>
  
            </div>
            
            </>
          )}
    </>)
})



export default DashboardBaseView;